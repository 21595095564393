<template>
    <div>
        <ChatButton @toggle-chat="toggleChat" />
        <transition name="chat-transition">
            <ChatWindow v-if="isChatOpen" ref="chatWindow" :messages="messages" :isLoading="isLoading" @send-message="sendMessage" @close-chat="toggleChat" />
        </transition>
    </div>
</template>

<script>
import ChatButton from "./ChatButton.vue";
import ChatWindow from "./ChatWindow.vue";
import axios from "axios";

const businessInfo = `
  
  General Business Information:
  
  Leadership Team:
  Founder & CEO: Abdellah Aouf
  Co-founder & COO: Mohamed Haithem Medani
  
  FAQs:
  General:
  What is the core concept of GO PLATFORM?
  GO PLATFORM focuses on Talent Success, which is achieved through:
  Awareness: Understanding employee skills and capabilities.
  Position Fit: Aligning the right talent with the right roles.
  Retention: Ensuring employees remain engaged and committed.
  
  What are the key components of Talent Success?
  The platform emphasizes:
  Skills Assessment: Evaluating technical, interpersonal, reasoning, communication, and behavioral skills.
  Personality Traits: Assessing integrity, honesty, self-motivation, optimism, enthusiasm, cooperativeness, and commitment.
  Application: Using insights to set goals, manage time, and improve decision-making.
  
  What are the benefits for employees?
  Employees benefit from:
  Recognition: Acknowledging their contributions and skills.
  Support: Connecting with skilled colleagues for mentoring and coaching.
  Job Satisfaction: Aligning work with their abilities and preferences.
  Growth: Tracking progress through transparent reporting.
  Opportunity: Showcasing their capabilities.
  Clarity: Clear pathways for career advancement.
  Buy-In: Involvement in the process for better engagement.
  Control: Tailoring learning and development plans.
  
  What are the benefits for managers?
  Managers benefit from:
  Playing to Strengths: Leveraging skills data to assign tasks effectively.
  Improved Resource Alignment: Identifying the right people for specific tasks.
  Enhanced Decision-Making: Accessing reliable data for informed decisions.
  Improved Culture: Building stronger teams through engagement.
  Enhanced Processes: Integrating skill insights into organizational processes.
  Reduction in Overhead: Distributing assessments to reduce administrative burden.
  Higher Quality Data: Producing reliable and structured skill-related data.
  Reduced Friction: Increasing transparency and buy-in.
  Less Re-Work: Minimizing errors and lost time.
  Integrability: Seamlessly integrating with other systems.
  
  What measurable results does the platform deliver?
  The platform provides:
  Turnover Reduction: A 72% decrease in turnover.
  Cost Efficiency: An 84% increase in cost efficiency.
  Position Fit: A 97% improvement in position fit.
  
  What do clients say about GO PLATFORM?
  The platform has earned a 5/5 rating from clients for its science-based solutions and structured methodology.
  
  Use Cases:
  What are the use cases for GO PLATFORM?
  The platform offers actionable strategies for:
  Learning & Development: Enhancing employee skills through tailored training.
  Workforce Planning: Identifying skill gaps and planning for future needs.
  Resource Allocation: Efficiently assigning tasks based on capabilities.
  Employee Performance Management: Tracking progress and providing feedback.
  Business Strategy: Leveraging skill insights for strategic decisions.
  Recruitment: Ensuring the best fit for new hires.
  Clients & Consultancy: Providing data-driven recommendations.
  Mergers & Acquisitions: Assessing skill compatibility during changes.
  Talent Acquisition: Streamlining hiring processes.
  Remuneration and Pay: Aligning compensation with skills and performance.
  Career Mobility: Facilitating employee growth.
  Succession Planning: Preparing for leadership transitions.
  Organizational Restructuring: Optimizing team structures.
  
  Skills Assessment:
  How does the Skills Assessment process work?
  The platform uses:
  5 Skills per Assessment: Technical, interpersonal, reasoning, communication, and behavioral skills.
  Artificial Intelligence (AI): Detects cheating attempts through facial recognition.
  Psychological Science: Ensures reliability and fairness, not relying on generative AI.
  
  Contact Information:
  How can I contact GO PLATFORM?
  Website: www.go-platform.com
  Email: contact@go-platform.dz
  Phone: +(1) 413 772 9811
  
  Social media links:
  LinkedIn: https://www.linkedin.com/company/go-platform 
  Facebook: https://www.facebook.com/go.platform
  Instagram: https://www.instagram.com/go.platform/ 
  
  
  Qatar Location:
  What is the address of the Qatar office?
  Address: QFC Tower 1, Diplomatic Area, West Bay, 9th Floor, Office N01, Doha, PO Box 23245, QA
  Note: We have only this office.
  
  
  Tone Instructions:
  - Conciseness: Respond in short, informative sentences.
  - Formality: Use polite language with slight formality (e.g., "Please let us know," "We are happy to assist").
  - Clarity: Avoid technical jargon unless necessary.
  - Consistency: Ensure responses are aligned in tone and style across all queries.
  Example: "Thank you for reaching out! Please let us know if you need further assistance."
  
  `;

export default {
    components: {
        ChatButton,
        ChatWindow,
    },
    data() {
        //console.log("API Key:", process.env.VUE_APP_OPENROUTER_API_KEY); // Debugging

        return {
            isChatOpen: false,
            messages: [],
            isLoading: false,
            hasInteracted: false,
            apiKey: process.env.VUE_APP_OPENROUTER_API_KEY,

            responseCache: new Map(),
        };
    },
    methods: {
        toggleChat() {
            this.isChatOpen = !this.isChatOpen;
        },
        async sendMessage(userMessage) {
            if (this.responseCache.has(userMessage)) {
                const cachedResponse = this.responseCache.get(userMessage);
                this.messages.push({
                    role: "assistant",
                    parts: [{ text: cachedResponse }],
                });
                return;
            }
            this.hasInteracted = true;
            if (!userMessage.trim()) return;

            // Add user message
            this.messages.push({
                role: "user",
                parts: [{ text: userMessage }],
            });

            this.isLoading = true;

            try {
                const response = await axios.post(
                    "https://openrouter.ai/api/v1/chat/completions",
                    {
                        model: "deepseek/deepseek-chat:free",
                        messages: [
                            {
                                role: "system",
                                content: businessInfo,
                            },
                            ...this.messages.map((msg) => ({
                                role: msg.role,
                                content: msg.parts[0].text,
                            })),
                        ],
                        temperature: 0.3,
                        max_tokens: 1024,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${this.apiKey}`,
                            "HTTP-Referer": "https://go-platform.com",
                            "X-Title": "GO Platform Assistant",
                        },
                    },
                );

                const aiResponse = response.data.choices[0].message.content;

                this.messages.push({
                    role: "assistant",
                    parts: [{ text: aiResponse }],
                });

                // After getting response:
                this.responseCache.set(userMessage, aiResponse);
            } catch (error) {
                console.error("API Error:", error);
                const errorMessage =
                    error.response?.status === 401
                        ? "Invalid API key. Get a free key from OpenRouter.ai"
                        : error.response?.status === 402
                          ? "Free quota exceeded. Please try again later."
                          : "Message failed. Please try again.";

                this.messages.push({
                    role: "error",
                    parts: [{ text: errorMessage }],
                });
            } finally {
                this.isLoading = false;
                this.$nextTick(() => {
                    this.$refs.chatWindow.scrollToBottom();
                });
            }
        },
    },
};
</script>

<style scoped>
body {
    margin: 0;
    padding: 0;
}

/* chat window transition */
.chat-transition-enter-active,
.chat-transition-leave-active {
    transition: all 0.3s ease;
}

.chat-transition-enter-from,
.chat-transition-leave-to {
    opacity: 0;
    transform: translateY(20px);
}

:root {
    --blue: #00aef0;
    --subtitle-gray: #767676;
    --medium-gray: #8c8c8c1a;
    --input-color: #8c8c8c3d;
    --placeholder-text-color: #424242;
    --scrollbar-color: #a2aac2;
}
</style>
